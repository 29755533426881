import Welcome from "./Components/Welcome.js";
import Chat from "./Components/Chat.js";
import SideBar from "./Components/SideBar.js";
import InputField from "./Components/InputField.js";
import Logo from "./banner.png";

const App = () => {
	console.log("si sono io");
	if (localStorage.getItem("username") !== null) {
		return (
			<>
				<div className="app">
					<SideBar></SideBar>
					<section className="main">
						<img src={Logo} alt="logo" className="logoHeader" />
						<Welcome></Welcome>
						<Chat></Chat>
						<div className="bottom-section">
							<InputField></InputField>
						</div>
					</section>
				</div>
			</>
		);
	} else {
		window.location.replace("/login");
	}
};

export default App;
