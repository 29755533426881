// import { useRef, useEffect } from "react";
// import { useGlobalContext } from "./Context.js";
// const InputField = () => {
// 	const { getQuestion, question, setQuestion, isLoading, username } =
// 		useGlobalContext();

// 	const submitquestion = (e) => {
// 		if (e.code === "Enter") {
// 			getQuestion();
// 		}
// 	};
// 	const inputRef = useRef(null);
// 	useEffect(() => {
// 		if (inputRef.current) {
// 			inputRef.current.focus(); // Imposta il focus sull'elemento di input all'avvio
// 		}

// 		return () => {
// 			// Rimuovi il focus quando il componente viene smontato
// 			if (inputRef.current) {
// 				inputRef.current.blur();
// 			}
// 		};
// 	}, []);
// 	if (username) {
// 		return (
// 			<>
// 				<div className="input-container">
// 					<input
// 						// ref={inputRef}
// 						// type="text"
// 						// value={isLoading ? "...in attesa di risposta" : question}
// 						// onChange={(e) => setQuestion(e.target.value)}
// 						// // readOnly={!isLoading ? false : true}
// 						// className={isLoading ? "waiting" : ""}
// 						// id="submitBtn"
// 						// onKeyUp={(e) => submitquestion(e)}
// 						ref={inputRef}
// 						type="text"
// 						value={isLoading ? "...in attesa di risposta" : question}
// 						onChange={(e) => setQuestion(e.target.value)}
// 						disabled={isLoading} // Utilizza 'disabled' invece di 'readOnly'
// 						className={isLoading ? "waiting" : ""}
// 						id="submitBtn"
// 						onKeyUp={(e) => submitquestion(e)}
// 					></input>
// 					<div id="submit" onClick={() => getQuestion()}>
// 						➣
// 					</div>
// 				</div>
// 			</>
// 		);
// 	}
// };

// export default InputField;
import { useRef, useEffect } from "react";
import { useGlobalContext } from "./Context.js";

const InputField = () => {
	const { getQuestion, question, setQuestion, isLoading, username } =
		useGlobalContext();

	const submitquestion = (e) => {
		if (e.code === "Enter") {
			getQuestion();
		}
	};

	const inputRef = useRef(null);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus(); // Imposta il focus sull'elemento di input dopo un ritardo
			}
		}, 300); // Ritardo di 100 millisecondi

		return () => {
			clearTimeout(timeout); // Cancella il timeout se il componente viene smontato
		};
	}, []);

	//if (username) {
	return (
		<>
			<div className="input-container">
				<input
					ref={inputRef}
					type="text"
					value={isLoading ? "...in attesa di risposta" : question}
					onChange={(e) => setQuestion(e.target.value)}
					disabled={isLoading}
					className={isLoading ? "waiting" : ""}
					id="submitBtn"
					onKeyUp={(e) => submitquestion(e)}
					placeholder="..."
				/>
				<div id="submit" onClick={() => getQuestion()}>
					➣
				</div>
			</div>
		</>
	);
	//	}

	return null;
};

export default InputField;
