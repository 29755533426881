import logo from "../logo.png";
import { useGlobalContext } from "./Context.js";
const SideBar = () => {
	const { createaNewChat, reset, arrayTitle, openCurrentChat, logout } =
		useGlobalContext();

	return (
		<>
			<section className="sidebar">
				<button onClick={() => createaNewChat()}>+ New Chat</button>
				<ul className="history">
					{arrayTitle?.map((unique, index) => (
						<li key={index} onClick={() => openCurrentChat(unique)}>
							{unique}
						</li>
					))}
				</ul>
				<button onClick={() => reset()}>RESET</button>
				<button onClick={() => logout()}>LOGOUT</button>
				<nav className="brandLogo">
					<img src={logo} alt="" />
				</nav>
			</section>
		</>
	);
};

export default SideBar;
