import React from "react";
import { useGlobalContext } from "./Context.js";
const Welcome = () => {
	const { username } = useGlobalContext();

//	if (username) {
		return (
			<>
				<ul className="welcome">
					<li className="first">
						<h6>Assistant</h6>
						<p>
							Ciao! Sono il sistema di intelligenza artificiale del MUSEO
							DIOCESANO, <span>{username}</span> dimmi cosa posso fare per te.
						</p>
					</li>
				</ul>
			</>
		);
//	}
};

export default Welcome;
