import React from "react";
import ReactDOM from "react-dom/client";
import { AppProvider } from "./Components/Context.js";
import App from "./App.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<AppProvider>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</AppProvider>
);
