import React, { useContext, useEffect, useReducer, useState } from "react";
import reducer from "./Reducer";

const AppContext = React.createContext();

const initialState = {
	username: "",
	messages: [],
	isLoading: false,
	question: "",
	currentTitle: "",
	arrayTitle: [],
	currentConversation: [],
};

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [value, setValue] = useState("");

	const openCurrentChat = (e) => {
		dispatch({ state, type: "SET_CURRENT_CHAT", payload: e });
	};

	const createaNewChat = () => {
		dispatch({ state, type: "CREATE_NEW_CONVERSATION" });
	};

	const setQuestion = (e) => {
		dispatch({ state, type: "SET_QUESTION", payload: e });
	};

	const setUsername = (name) => {
		dispatch({ state, type: "SET_USERNAME", payload: name });
	};

	const getQuestion = async () => {
		if (!state.isLoading && state.question) {
			dispatch({ state, type: "GET_QUESTION" });
		} else {
			alert("Nessuna domanda inserita");
		}
	};

	const reset = () => {
		setValue("");
		dispatch({ state, type: "RESET" });
	};

	const logout = () => {
		dispatch({ state, type: "LOGOUT" });
		window.location.href("/login");
	};

	useEffect(() => {
		var chatHistory = document.getElementById("feed");
		if (chatHistory) {
			chatHistory.scrollTop = chatHistory.scrollHeight + 350;
		}
	}, [JSON.stringify(state.messages)]);

	useEffect(() => {
		if (state.isLoading) {
			const options = {
				method: "POST",
				body: JSON.stringify({
					question: state.question,
					format: "html",
				}),
				headers: {
					"Content-Type": "application/json",
				},
			};
			const getResponse = async () => {
				const response = await fetch("/completions", options);
				const data = await response.text();
				if (state.currentTitle === "") {
					state.currentTitle = state.question;
					state.arrayTitle.push(state.question);
				}

				dispatch({ state, type: "GET_RESPONSE", payload: data });
			};
			getResponse();
		}
	}, [state.isLoading]);

	useEffect(() => {
		if (localStorage.getItem("username")) {
			setUsername(localStorage.getItem("username"));
		}
	}, []);
	useEffect(() => {
		if (state.username === null) {
			window.location.replace("/login");
		}
	}, [state.username]);

	return (
		<>
			<AppContext.Provider
				value={{
					...state,
					setUsername,
					getQuestion,
					reset,
					value,
					setValue,
					setQuestion,
					createaNewChat,
					openCurrentChat,
					logout,
				}}>
				{children}
			</AppContext.Provider>
		</>
	);
};

const useGlobalContext = () => {
	return useContext(AppContext);
};

export { AppProvider, useGlobalContext };
